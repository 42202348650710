.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-style: normal;
    color: #ffffff;
    font-size: 24px;
    margin: 8px 0 2px 0px;
    letter-spacing: 0.3vw;
}

.description {
    font-style: normal;
    font-weight: 700;
    color: rgb(153, 153, 153);
    font-size: 14px;
    line-height: 20px;
    margin: 2px 0 28px 0px;
}

.buttonContainer {
    margin-bottom: 20px;
}

.button {
    padding: 8px 24px;
    border-radius: 100px;
    color: rgb(17, 17, 17);
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 40px;
    background-color: white;
    border: none;
    text-decoration: none;
}

.button:hover {
    opacity: 0.8;
}

.showreelVideo {
    margin: 16px 0px 32px;
}

.video {
    width: 90vw;
    height: calc(90vw / 2.35);
}
